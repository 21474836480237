/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { MaybeRef } from 'vue'
import { computed, toValue } from 'vue'
import type { Route } from '~/src/Application/Shared/Router/RouterInterface'
import type User from '~/src/Domain/Identity/User'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'

export default function useUserNavigation(user: MaybeRef<User>) {
  const { getLocalePath, navigateTo, uuidEncoder } = useNavigation()

  const params = computed(() => ({
    userId: uuidEncoder.encode(toValue(user)['@id'].toId()),
    userSlug: toValue(user).getSlug().toString(),
  }))

  const getUserLocalePath = (route: Route) => {
    if (typeof route === 'string') {
      return getLocalePath({
        name: route,
        params: params.value,
      })
    }

    route.params = {
      ...route.params,
      ...params.value,
    }

    return getLocalePath(route)
  }

  const userSettingsProfileLocalePath = getUserLocalePath('settings-profile-Profile')
  const userSettingsProfileEditLocalePath = getUserLocalePath('settings-profile-ProfileEdit')
  const userSettingsExportsLocalePath = getUserLocalePath('settings-profile-Exports')

  const navigateToUserSettingsProfilePage = async () =>
    navigateTo(userSettingsProfileLocalePath)

  return {
    userSettingsProfileLocalePath,
    userSettingsProfileEditLocalePath,
    userSettingsExportsLocalePath,
    navigateToUserSettingsProfilePage,
  }
}
